module controllers {
    export module purchase {
        interface IPurchaseSkuUpdateScope extends ng.IScope {
            pOUpdateCtrl: controllers.purchase.PurchaseOrderUpdateCtrl
        }

        interface IPurchaseUpdateParams extends ng.ui.IStateParamsService {
            posId: number;
            poId: number;
        }

        export class purchaseOrderSkuUpdateCtrl {
            static $inject = ["$scope", '$rootScope', 'generalService', '$q', '$stateParams', "purchaseOrderSKUService", "productColourService", "productSizeService", "packConfigurationService", "$state",
                "classificationValueService", "unitOfMeasureService", "vatRateService", "$anchorScroll", "productService", "skuService", "bsLoadingOverlayService",
                "countryService", "lookupService", "purchaseOrderDetailVatRateService", "$timeout","menuService"];

            public isDisabled: boolean = false;

            public poSku: interfaces.purchase.IPurchaseOrderSKU;
            breadCrumbDesc: string;
            poNumber: string;
            selectedTab: number = 0;
            
            selectedInternationalTrade: interfaces.purchase.IPurchaseOrderNationalMeasureCode;

            apiUoM: uiGrid.IGridApi;
            apiVAT: uiGrid.IGridApi;
            apiTrade: uiGrid.IGridApi;

            skuLookup: interfaces.applicationcore.ILookupDirective;

            tariffTypes: interfaces.applicationcore.IDropdownModel[];

        
            allowTariffPurchaseOrder: boolean;

            constructor(private $scope: IPurchaseSkuUpdateScope, private $rootScope: interfaces.applicationcore.IRootScope, public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService, private $stateParams: IPurchaseUpdateParams, private purchaseOrderSKUService: interfaces.purchase.IPurchaseOrderSKUService,
                public productColourService: interfaces.master.IProductColourService, public productSizeService: interfaces.master.IProductSizeService,
                public packConfigurationService: interfaces.master.IPackConfigurationService, private $state: ng.ui.IStateService,
                public classificationValueService: interfaces.applicationcore.IClassificationValueService, public unitOfMeasureService: interfaces.master.IUnitOfMeasureService,
                public vatRateService: interfaces.master.IVatRateService, public $anchorScroll: ng.IAnchorScrollService,
                public productService: interfaces.master.IProductService, public skuService: interfaces.master.ISKUService,
                public bsLoadingOverlayService, public countryService: interfaces.applicationcore.ICountryService,
                public lookupService: interfaces.applicationcore.ILookupService,
                public purchaseOrderDetailVatRateService: interfaces.purchase.IPurchaseOrderDetailVatRateService,
                public $timeout: ng.ITimeoutService,
                private menuService: interfaces.applicationcore.IMenuService) {

                var loadPromises: ng.IPromise<any>[] = [];

                loadPromises.push(this.loadSku($stateParams.posId, $stateParams.poId));
                loadPromises.push(this.loadTariffTypes());
                loadPromises.push(this.getAllowTariffPurchaseOrderRight());

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'po.skuupdate'
                },
                    () => {
                        return $q.all(loadPromises);
                    });
            }

            getAllowTariffPurchaseOrderRight() {
                this.allowTariffPurchaseOrder = false;                
                return this.menuService.getGTSConnectMenuItem(655).get((result: interfaces.applicationcore.IMenu) => {
                    if (result.name) {
                        this.allowTariffPurchaseOrder = true;
                    }
                }).$promise;
            }

            public loadSku(posId: number, ordId: number) {
                return this.purchaseOrderSKUService.getDetail().query({ posId: posId, ordId: ordId }, (data: interfaces.purchase.IPurchaseOrderSKU) => {
                    this.poSku = data;
                    this.breadCrumbDesc = data.PurchaseOrderDetail.SequenceNumber + " - " + (data.SKU ? data.SKU.Code + " - " + data.SKU.Description : "");

                    this.isDisabled = this.poSku.PurchaseOrderDetail.IsIntegrated;

                    this.gvwPOSUoM.data = this.poSku.UoMList;
                    this.gvwPOSVAT.data = this.poSku.PurchaseOrderDetail.PurchaseOrderDetailVatRateList;
                    this.gvwPOSTrade.data = this.poSku.PurchaseOrderDetail.PurchaseOrderNationalMeasureCodeList;

                    this.poSku.OutstandingQty = this.poSku.OrderedQty - this.poSku.InvoiceQty;

                }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
            }

            loadSKUs(searchText: string) {
                if (this.poSku) {
                    return this.skuService.getDropdownList(this.poSku.PurchaseOrderDetail.OwnerEntityId, searchText).query(() => {

                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            loadTariffTypes() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.TariffCodeType
                }, (resultList) => {
                    this.tariffTypes = resultList;
                }).$promise;
            }

            recalculateValues() {
                this.poSku.PriceQty = this.poSku.OrderPriceConvertion * this.poSku.OrderedQty;
                this.poSku.SupplierValue = this.poSku.SupplierPrice * this.poSku.PriceQty;
                this.poSku.BaseCurrencySellingValue = this.poSku.BaseCurrencySellingPrice * this.poSku.OrderedQty;
            }

            loadProductColours() {
                var defered = this.$q.defer();

                if (this.poSku && this.poSku.PurchaseOrderDetail) {
                    this.productColourService.getDropdownList().query({ ownerEntityId: this.poSku.PurchaseOrderDetail.OwnerEntityId }, (data: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                        defered.resolve(data);
                    }, () => {
                        defered.resolve([]);
                    });
                } else {
                    defered.resolve([]);
                }
                
                return defered.promise;
            }

            loadCountries() {
                var defered = this.$q.defer();

                this.countryService.getDropdownList().query({  }, (data: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                    defered.resolve(data);
                }, () => {
                    defered.resolve([]);
                });


                return defered.promise;
            }

            loadProductSizes() {
                var defered = this.$q.defer();

                if (this.poSku && this.poSku.PurchaseOrderDetail) {
                    this.productSizeService.getDropdownList().query({ ownerEntityId: this.poSku.PurchaseOrderDetail.OwnerEntityId }, (data: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                        defered.resolve(data);
                    }, () => {
                        defered.resolve([]);
                    });
                } else {
                    defered.resolve([]);
                }

                return defered.promise;
            }

            loadPackConfigurations(searchText: string) {
                var defered = this.$q.defer();

                if (this.poSku && this.poSku.PurchaseOrderDetail) {
                    this.packConfigurationService.getDropdownList().query({ ownerEntityId: this.poSku.PurchaseOrderDetail.OwnerEntityId, searchText: searchText }, (data: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                        defered.resolve(data);
                    }, () => {
                        defered.resolve([]);
                    });
                } else {
                    defered.resolve([]);
                }
                
                return defered.promise;
            }

            loadUoMList() {
                if (this.poSku && this.poSku.PurchaseOrderDetail) {
                    return this.unitOfMeasureService.getUoMDropdown().query({ ownerEntityId: this.poSku.PurchaseOrderDetail.OwnerEntityId }).$promise;
                } else {
                    var defered = this.$q.defer();
                    defered.resolve([]);
                    return defered.promise;
                }
            }

            public loadVatRates() {
                if (this.poSku && this.poSku.PurchaseOrderDetail) {
                    return this.vatRateService.getDropdownList().query({ ownerEntityId: this.poSku.PurchaseOrderDetail.OwnerEntityId }).$promise;
                } else {
                    var defered = this.$q.defer();
                    defered.resolve([]);
                    return defered.promise;
                }
            }

            insertVatRate() {
                this.poSku.PurchaseOrderDetail.PurchaseOrderDetailVatRateList.push(<interfaces.purchase.IPurchaseOrderDetailVatRate>{
                    Id: 0,
                    PurchaseOrderDetailId: this.poSku.PurchaseOrderDetail.Id,
                    DateFrom: moment().utc(),
                    IsActive: true
                });
            }

            removeVatRate() {
                if (this.apiVAT.selection.getSelectedRows().length > 0) {
                    this.generalService.displayConfirmationBox("Confirm Remove", "Are you sure you want to remove the Vat Rate?").then((result) => {
                        var selectedRow: interfaces.purchase.IPurchaseOrderDetailVatRate = this.apiVAT.selection.getSelectedRows()[0];
                        if (result && selectedRow.Id <= 0) {
                            _.remove(this.poSku.PurchaseOrderDetail.PurchaseOrderDetailVatRateList, selectedRow);
                        } else if (result) {

                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'po.skuupdate'
                            },
                                () => {
                                    return this.purchaseOrderDetailVatRateService.remove(selectedRow.Id).remove((result: interfaces.applicationcore.IMessageHandler) => {
                                        this.generalService.displayMessageHandler(result);

                                        if (!result.HasErrorMessage)
                                            this.loadSku(this.$stateParams.posId, this.$stateParams.poId);
                                    }, (errorResponse) => {
                                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                        }).$promise;
                                });

                            
                        }
                    });
                    
                }
            }

            vatRateChanged(model: interfaces.applicationcore.IDropdownModel, vatRate: interfaces.purchase.IPurchaseOrderDetailVatRate) {

                if (model && model.Id) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'po.skuupdate'
                    },
                        () => {
                            return this.vatRateService.getVatRate().get({ mvrId: model.Id }, (result: interfaces.master.IVatRate) => {
                                vatRate.Rate = result.CurrentRate;
                                vatRate.VatCountryDescription = result.CountryDescription;
                            });
                        });
                }
                
            }

            insertUoM() {
                this.poSku.UoMList.push(<interfaces.purchase.IPurchaseOrderSKUUoM>{
                    PurchaseOrderSKUId: this.poSku.Id,
                    IsActive: true
                });
            }

            changeUoM(poSKUUom: interfaces.purchase.IPurchaseOrderSKUUoM) {
                this.apiUoM.rowEdit.setRowsDirty([poSKUUom]);
            }

            skuChanged(model: interfaces.applicationcore.IDropdownModel) {
                if (model) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'po.skuupdate'
                    },
                        () => {
                            return this.loadSkuDefaults(model.Id);
                        });
                }
                
            };

            loadSkuDefaults(Id: number) {

                return this.purchaseOrderSKUService.getSKUDefaults().query({ skuId: Id, posId: this.poSku.Id }, (result: interfaces.master.ISKU) => {
                    this.breadCrumbDesc = this.poSku.PurchaseOrderDetail.SequenceNumber + " - " + result.Code;

                    this.poSku.PurchaseOrderDetail.ProductId = result.ProductId;
                    this.poSku.PurchaseOrderDetail.ProductCode = result.Product.Code;
                    this.poSku.PurchaseOrderDetail.ProductDescription = result.Product.ShortDescription;
                    this.poSku.PurchaseOrderDetail.ProductLongDescription = result.Product.LongDescription;

                    this.poSku.PurchaseOrderDetail.OrderUoM = <interfaces.applicationcore.IDropdownModel>{
                        Id: result.Product.OrderUnitOfMeasureId,
                        Code: result.Product.UnitOfMeasureCode,
                        Description: result.Product.UnitOfMeasureDescription,
                        Display: result.Product.UnitOfMeasureCode
                    }

                    this.poSku.PurchaseOrderDetail.PricingUoM = <interfaces.applicationcore.IDropdownModel>{
                        Id: result.Product.PricingUnitOfMeasureId,
                        Code: result.Product.UnitOfPricingCode,
                        Description: result.Product.UnitOfPricingDescription,
                        Display: result.Product.UnitOfPricingCode
                    }

                    this.poSku.PackageConfiguration = result.PackConfiguration;
                    this.poSku.Colour = result.ProductColour;
                    this.poSku.Size = result.ProductSize;

                    this.poSku.PurchaseOrderDetail.ProductCategoryHierarchicalId = result.Product.ProductCategoryId;
                    this.poSku.PurchaseOrderDetail.ProductCategoryHierarchicalCode = result.Product.CategoryCode;
                    this.poSku.PurchaseOrderDetail.ProductCategoryDescription = result.Product.CategoryDescription;

                    this.poSku.UoMList = _.map(result.ProductUnitOfMeasures, (o: interfaces.master.IProductUnitOfMeasure) => {
                        return <interfaces.purchase.IPurchaseOrderSKUUoM>{
                            PurchaseOrderSKUId: this.poSku.Id,
                            UoM: <interfaces.applicationcore.IDropdownModel>{
                                Id: o.UnitOfMeasureId,
                                Code: o.UOMCode,
                                Description: o.UOMDescription,
                                Display: o.UOMCode + ' - ' + o.UOMDescription
                            },
                            Quantity: o.UnitOfMeasureQuantity,
                            IsActive: true
                        }
                    });

                    this.gvwPOSUoM.data = this.poSku.UoMList;

                    this.poSku.PurchaseOrderDetail.PurchaseOrderDetailVatRateList = _.map(result.Product.ProductVatRates, (o: interfaces.master.IProductVatRate) => {
                        return <interfaces.purchase.IPurchaseOrderDetailVatRate>{
                            PurchaseOrderDetailId: this.poSku.PurchaseOrderDetail.Id,
                            VatRate: <interfaces.applicationcore.IDropdownModel>{
                                Id: o.VatRate.Id,
                                Code: o.VatRate.Code,
                                Display: o.VatRate.Code
                            },
                            DateFrom: o.StartDate,
                            DateTo: o.EndDate,
                            Rate: o.CurrentVatRate,
                            IsActive: true,
                            VatCountryDescription: o.CountryDescription
                        }
                    });

                    this.gvwPOSVAT.data = this.poSku.PurchaseOrderDetail.PurchaseOrderDetailVatRateList;

                    this.poSku.OrderedQty = 0;
                    this.poSku.PriceQty = 0;
                    this.poSku.SupplierPrice = 0;
                    this.poSku.SupplierValue = 0;
                    this.poSku.BaseCurrencySellingPrice = 0;
                    this.poSku.BaseCurrencySellingValue = 0;

                    this.poSku.PurchaseOrderDetail.OriginCountry = result.Product.CountryOfOrigin;
                    
                    this.poSku.PurchaseOrderDetail.PurchaseOrderNationalMeasureCodeList = _.map(result.Product.ProductCountryNationalMeasures, (o: interfaces.master.IProductNationalMeasure) => {
                        return <interfaces.purchase.IPurchaseOrderNationalMeasureCode>{
                            CodeTypeClassificationValueId: o.CodeTypeClassificationValueId,
                            CodeTypeClassificationValueDescription: o.CodeTypeClassificationValueDescription,
                            ImportDuty: "",
                            NationalMeasureCountryId: o.NationalMeasureCountryId,
                            NationalMeasureCountryCode: o.NationalMeasureCountryCode,
                            NationalMeasureCode: o.NationalMeasureCode,
                            StartDate: o.StartDate,
                            EndDate: o.EndDate,
                            PreviousProcedureMRN: ""
                        }
                    });

                    this.gvwPOSTrade.data = this.poSku.PurchaseOrderDetail.PurchaseOrderNationalMeasureCodeList;
                }).$promise;
            }

            addInternationalTrade_click() {
                this.lookupService.openTariffLookup(undefined, Enum.EnumTariffCodeType.NationMeasure, true,null,this.poSku.PurchaseOrderDetail.OwnerEntityId).result.then(
                    (result: interfaces.tariff.INationalMeasureCodeLookup) => {
                        this.addInternationalTrade(result);
                    }, (errorResult) => {

                    });
            }

            editPurchaseOrderNationalMeasureCode(internationalTrade: interfaces.purchase.IPurchaseOrderNationalMeasureCode) {
                this.lookupService.openTariffLookup(internationalTrade.NationalMeasureCountryId, Enum.EnumTariffCodeType.NationMeasure, true, internationalTrade.NationalMeasureCode,this.poSku.PurchaseOrderDetail.OwnerEntityId,internationalTrade.CodeTypeClassificationValueId).result.then(
                    (lookupResult: interfaces.tariff.INationalMeasureCodeLookup) => {

                        this.bsLoadingOverlayService.wrap({
                        }, () => {
                            return this.countryService.get().get({ cntId: lookupResult.CountryId }, (countryResult) => {

                                internationalTrade.CodeTypeClassificationValueId = lookupResult.CodeTypeClassificationValueId;
                                internationalTrade.CodeTypeClassificationValueDescription = _.find(this.tariffTypes, (o) => { return o.Id === lookupResult.CodeTypeClassificationValueId }).Description;
                                internationalTrade.ImportDuty = lookupResult.DutyTypeCode;
                                internationalTrade.NationalMeasureCountryId = lookupResult.CountryId;
                                internationalTrade.NationalMeasureCode = lookupResult.NationalMeasureCode;
                                internationalTrade.NationalMeasureCountryCode = countryResult.Code;

                                if (this.apiTrade) {
                                    this.$timeout(() => {
                                        this.apiTrade.rowEdit.setRowsDirty([internationalTrade]);
                                    });
                                }

                            }).$promise;
                            });

                        
                    }, (errorResult) => {

                    });
            }

            addInternationalTrade(nationalMeasureCodeLookup: interfaces.tariff.INationalMeasureCodeLookup) {
                this.bsLoadingOverlayService.wrap({
                }, () => {
                    return this.countryService.get().get({ cntId: nationalMeasureCodeLookup.CountryId }, (result) => {

                        var poNationalMeasure = {
                            CodeTypeClassificationValueId: nationalMeasureCodeLookup.CodeTypeClassificationValueId,
                            CodeTypeClassificationValueDescription: _.find(this.tariffTypes, (o) => { return o.Id === nationalMeasureCodeLookup.CodeTypeClassificationValueId }).Description,
                            ImportDuty: nationalMeasureCodeLookup.DutyTypeCode,
                            NationalMeasureCountryId: nationalMeasureCodeLookup.CountryId,
                            NationalMeasureCode: nationalMeasureCodeLookup.NationalMeasureCode,
                            NationalMeasureCountryCode: result.Code,
                            StartDate: moment.utc(),
                            EndDate: undefined,
                            IsActive: true
                        };

                        this.poSku.PurchaseOrderDetail.PurchaseOrderNationalMeasureCodeList.push(<interfaces.purchase.IPurchaseOrderNationalMeasureCode>poNationalMeasure);

                        if (this.apiTrade) {
                            this.$timeout(() => {
                                this.apiTrade.rowEdit.setRowsDirty([poNationalMeasure]);
                            });
                        }
                    }).$promise;
                });
            }

            removeInternationalTrade_click() {
                if (this.apiTrade.selection.getSelectedRows().length > 0) {
                    this.generalService.displayConfirmationBox("Confirm Remove", "Are you sure you want to remove the record?").then((result) => {

                        var selectedInternationalTrade = this.apiTrade.selection.getSelectedRows()[0];

                        if (result && selectedInternationalTrade.Id <= 0) {
                            _.remove(this.poSku.PurchaseOrderDetail.PurchaseOrderNationalMeasureCodeList, selectedInternationalTrade);
                        } else {
                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'po.skuupdate'
                            },
                                () => {
                                    return this.purchaseOrderSKUService.deleteNationalMeasureCode(selectedInternationalTrade.Id).delete().$promise.then((result) => {
                                        this.generalService.displayMessageHandler(result);
                                        if(!result.HasErrorMessage) {
                                            _.remove(this.poSku.PurchaseOrderDetail.PurchaseOrderNationalMeasureCodeList, selectedInternationalTrade);
                                        }
                                    }, (errorResponse) => {
                                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                    });
                                });
                        }
                    });

                }
            }

            gvwPOSUoM: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: false,
                useExternalFiltering: false,
                enableCellEdit: true,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: false,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                onRegisterApi: (gridApi) => { this.registerUoMApi(gridApi); },
                cellEditableCondition: () => { return this.$scope.pOUpdateCtrl.PurchaseOrder ? this.$scope.pOUpdateCtrl.PurchaseOrder.LockdownType < 2 : true },
                columnDefs: [{
                    name: 'UoM',
                    displayName: 'UoM',
                    field: 'UoM',
                    enableCellEditOnFocus: true,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Display}}</div>`,
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <gts-dropdown ng-disabled="MODEL_COL_FIELD && MODEL_COL_FIELD.Id" ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.pOSKUUpdateCtrl.loadUoMList()"></gts-dropdown>
                                </form>`,
                    enableSorting: false,
                    width: 250
                }, {
                    name: 'Qty',
                    field: 'Quantity',
                    displayName: 'Qty',
                    enableCellEditOnFocus: true,
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="number" ignore-wheel name="orderedQty" ng-model="MODEL_COL_FIELD" class="form-control" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.00001" min="0" />
                                    </p>
                                </form>`,
                        cellClass: 'text-right',
                    enableSorting: false,
                    width: 150
                },
                {
                    name: 'IsActive',
                    field: 'IsActive',
                    displayName: 'Active',
                    enableCellEditOnFocus: true,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD ? 'Yes' : 'No'}}</div>`,
                    editableCellTemplate: `
                        <form name="inputForm">
                            <p class="input-group-sm">
                                <input type="checkbox" ng-model="MODEL_COL_FIELD" ng-click="grid.appScope.pOSKUUpdateCtrl.changeUoM(row.entity)">
                            </p>
                        </form>`,
                    cellClass: 'text-right',
                    enableSorting: false,
                    width: 150
                }
                ]
            };

            gvwPOSVAT: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: false,
                useExternalFiltering: false,
                enableCellEdit: true,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: false,
                useExternalPagination: false,
                enableRowHeaderSelection: true,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                onRegisterApi: (gridApi) => { this.registerVATApi(gridApi); },
                cellEditableCondition: () => { return this.$scope.pOUpdateCtrl.PurchaseOrder ? this.$scope.pOUpdateCtrl.PurchaseOrder.LockdownType < 2 : true},
                columnDefs: [{
                    name: 'COUNTRY',
                    displayName: 'VAT Country',
                    field: 'VatCountryDescription',
                    enableSorting: false,
                    enableCellEdit: false,
                    width: 150
                },{
                    name: 'VAT',
                    displayName: 'VAT Code',
                    field: 'VatRate',
                    enableCellEditOnFocus: true,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Display}}</div>`,
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <gts-dropdown ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.pOSKUUpdateCtrl.loadVatRates()" ng-change="grid.appScope.pOSKUUpdateCtrl.vatRateChanged(model, row.entity)"></gts-dropdown>
                                </form>`,
                    enableSorting: false,
                    width: 250
                    }, {
                        name: 'DATEFROM',
                        displayName: 'Date From',
                        field: 'DateFrom',
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: "YYYY/MM/DD"}}</div>`,
                        editableCellTemplate: `
                                <form name="inputForm">
                                        <gts-date format="yyyy/MM/dd" ng-model="MODEL_COL_FIELD" name="{{MODEL_COL_FIELD.$$hashKey}}-fromdate" ng-required="true"></gts-date>
                                </form>`,
                        enableSorting: false,
                        width: 250
                    }, {
                        name: 'DATETO',
                        displayName: 'Date To',
                        field: 'DateTo',
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: "YYYY/MM/DD"}}</div>`,
                        editableCellTemplate: `
                                <form name="inputForm">
                                        <gts-date format="yyyy/MM/dd" ng-model="MODEL_COL_FIELD" name="{{MODEL_COL_FIELD.$$hashKey}}-todate" ng-required="true"></gts-date>
                                </form>`,
                        enableSorting: false,
                        width: 250
                    },{
                        name: 'RATE',
                        displayName: 'Rate',
                        field: 'Rate',
                        enableSorting: false,
                        enableCellEdit: false,
                        cellClass: 'text-right',
                        cellFilter: "number: 2",
                        width: 100
                    }]
            };

            gvwPOSTrade: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: false,
                useExternalFiltering: false,
                enableCellEdit: true,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: false,
                useExternalPagination: false,
                enableRowHeaderSelection: true,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                onRegisterApi: (gridApi) => { this.registerTradeApi(gridApi); },
                cellEditableCondition: () => { return this.$scope.pOUpdateCtrl.PurchaseOrder ? this.$scope.pOUpdateCtrl.PurchaseOrder.LockdownType < 2 : true },
                columnDefs: [{
                    name: "EDIT",
                    displayName: "",
                    enableFiltering: false,
                    enableCellEdit: false,
                    cellTemplate: `
                    <div class="GridButton"> 
                        <button type="button" ng-disabled="!grid.appScope.pOSKUUpdateCtrl.allowTariffPurchaseOrder" class="btn btn-default btn-sm" ng-click="grid.appScope.pOSKUUpdateCtrl.editPurchaseOrderNationalMeasureCode(row.entity)">
                            <span class="fa fa-pencil"></span>
                        </button>
                    </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 35
                }, {
                        name: 'CODETYPE',
                        displayName: 'Code Type',
                        field: 'CodeTypeClassificationValueDescription',
                        enableSorting: false,
                        enableCellEdit: false,
                        width: 150
                    }, {
                        name: 'COUNTRY',
                        displayName: 'Country',
                        field: 'NationalMeasureCountryCode',
                        enableSorting: false,
                        enableCellEdit: false,
                        width: 75
                    }, {
                        name: 'CODE',
                        displayName: 'Code',
                        field: 'NationalMeasureCode',
                        enableSorting: false,
                        enableCellEdit: false,
                        width: 150
                    }, {
                        name: 'STARTDATE',
                        displayName: 'Start Date',
                        field: 'StartDate',
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: "YYYY/MM/DD"}}</div>`,
                        editableCellTemplate: `
                                <form name="inputForm">
                                        <gts-date format="yyyy/MM/dd" ng-model="MODEL_COL_FIELD" name="{{MODEL_COL_FIELD.$$hashKey}}-startDate" ng-required="true"></gts-date>
                                </form>`,
                        enableSorting: false,
                        width: 100
                    }, {
                        name: 'ENDDATE',
                        displayName: 'End Date',
                        field: 'EndDate',
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: "YYYY/MM/DD"}}</div>`,
                        editableCellTemplate: `
                                <form name="inputForm">
                                        <gts-date format="yyyy/MM/dd" ng-model="MODEL_COL_FIELD" name="{{MODEL_COL_FIELD.$$hashKey}}-endDate"></gts-date>
                                </form>`,
                        enableSorting: false,
                        width: 100
                    }, {
                        name: 'PreviousProcedureLineNo',
                        field: 'PreviousProcedureLineNo',
                        displayName: 'Previous Procedure Line',
                        enableCellEditOnFocus: true,
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="text" name="{{MODEL_COL_FIELD.$$hashKey}}-PreviousProcedureLineNo" ng-model="MODEL_COL_FIELD" class="form-control" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor />
                                    </p>
                                </form>`,
                        enableSorting: false,
                        width: 200
                    }, {
                        name: 'PreviousProcedureMRN',
                        field: 'PreviousProcedureMRN',
                        displayName: 'Previous Procedure MRN',
                        enableCellEditOnFocus: true,
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="text" name="{{MODEL_COL_FIELD.$$hashKey}}-PreviousProcedureMRN" ng-model="MODEL_COL_FIELD" class="form-control" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor />
                                    </p>
                                </form>`,
                        enableSorting: false,
                        width: 250
                    }, {
                        name: 'IMPDUTY',
                        displayName: 'Import Duty',
                        field: 'ImportDuty',
                        enableSorting: false,
                        enableCellEdit: false,
                        width: 150
                    }]
            };

            registerUoMApi(gridApi: uiGrid.IGridApi) {
                this.apiUoM = gridApi;
            }

            registerVATApi(gridApi: uiGrid.IGridApi) {
                this.apiVAT = gridApi;
            }

            registerTradeApi(gridApi: uiGrid.IGridApi) {
                this.apiTrade = gridApi;
            }

            SavePurchaseSKU(): ng.IPromise<boolean> {
                var deferre = this.$q.defer<boolean>();

                if (this.apiUoM) {
                    this.poSku.UoMList = _.map(this.apiUoM.rowEdit.getDirtyRows(this.apiUoM.grid), (o) => { return o.entity });
                } else {
                    this.poSku.UoMList = [];
                }
                
                if (this.apiVAT) {
                    this.poSku.PurchaseOrderDetail.PurchaseOrderDetailVatRateList = _.map(this.apiVAT.rowEdit.getDirtyRows(this.apiVAT.grid), (o) => { return o.entity });
                } else {
                    this.poSku.PurchaseOrderDetail.PurchaseOrderDetailVatRateList = [];
                }

                if (this.apiTrade) {
                    this.poSku.PurchaseOrderDetail.PurchaseOrderNationalMeasureCodeList = _.map(this.apiTrade.rowEdit.getDirtyRows(this.apiTrade.grid), (o) => { return o.entity });
                } else {
                    this.poSku.PurchaseOrderDetail.PurchaseOrderNationalMeasureCodeList = [];
                }

                this.purchaseOrderSKUService.save().save(this.poSku, (data: interfaces.applicationcore.IMessageHandler) => {
                    this.generalService.displayMessageHandler(data);
                    if (!this.poSku.Id) {
                        this.poSku.Id = Number(data.ID);
                    }

                    if (this.apiUoM) {
                        this.apiUoM.rowEdit.setRowsClean(this.poSku.UoMList);
                    }

                    if (this.apiVAT) {
                        this.apiVAT.rowEdit.setRowsClean(this.poSku.PurchaseOrderDetail.PurchaseOrderDetailVatRateList);
                    }

                    if (this.apiTrade) {
                        this.apiTrade.rowEdit.setRowsClean(this.poSku.PurchaseOrderDetail.PurchaseOrderNationalMeasureCodeList);
                    }

                    deferre.resolve(true);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    deferre.resolve(false);
                });

                return deferre.promise;
            };

            Save() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'po.skuupdate'
                },
                    () => {
                        return this.SavePurchaseSKU().then((data: boolean) => {
                            if (data) {
                                this.loadSku(this.$stateParams.posId, this.$stateParams.poId);
                                this.$anchorScroll("topAnchor");
                                this.$state.go("auth.PurchaseOrders.Update.Sku", { posId: this.poSku.Id });
                            }
                        });
                    });
                
            }

            SaveClose() {
                

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'po.skuupdate'
                },
                    () => {
                        return this.SavePurchaseSKU().then((data) => {
                            if (data) {
                                this.$state.go("^");
                            } else {
                                this.$anchorScroll("topAnchor");
                            }
                        });
                    });
            }

            Close() {
                this.$state.go("^");
            }
            
        }

        angular.module("app").controller("purchaseOrderSkuUpdateCtrl", controllers.purchase.purchaseOrderSkuUpdateCtrl);
    }
}